import styled from "@emotion/styled";
import { H5 } from "@styles/Global.styles";
import { KpButton, LazyLoadImage } from "@elements/index";
import mq from '@styles/mq'
import React from "react";
import theme from "@styles/theme";

export const CheckoutSelectionCardContainer = styled.div`
    border-radius: 12px;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    position: relative;
    ${mq({
        width: ['100%', '100%', '100%'],
        padding: ['16px', '25px', '25px']
    })}
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.selected {
        &::before {
            content: '';
            color: transparent;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: solid 5px #3dae2b !important;
            border-radius: 12px;
        }
    }

    &.selectedMan {
        border: solid 5px #3dae2b !important;
        border-radius: 18px;
    }
    .ui.radio.checkbox input:checked ~ label:after{
        background-color: #3DAE2B;
    }
        .ui.checkbox input:checked ~ label:before{
        border-color: #3DAE2B;
        }
`

export const CheckoutSelectionCardButton = styled(KpButton)`
    min-width: 140px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 40px !important;
`

export const PlatemanufactureSelectionCardButton = styled(KpButton)`
    min-width: 140px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 40px !important;

    background-color: #FFF !important;
    border-color: #000 !important;
    color: #000 !important;
    float: right;

    &:hover {
        background-color: ${theme.brand.colors.green } !important;
        border-color: ${theme.brand.colors.green} !important;
        color: ${theme.brand.colors.black} !important;
    }
`

export const CheckoutSelectionCardHeading = styled(H5)`
    margin-bottom: 5px !important;
    ${mq({
        fontSize: ['16px !important', '16px !important', '20px !important']
    })}
    img {
        margin-right:12px;
    }
`

export const CheckoutSelectionCardIndicator = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    padding-bottom: 4px;
    color: white;
    background-color: #3dae2b;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 12px;
`

export const CheckoutSelectionCardIndicatorPayment = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 8px;
    padding-bottom: 4px;
    color: white;
    background-color: #3dae2b;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
`
export const CheckoutIconImage = styled(LazyLoadImage)`
    width: 32px;
    height: 32px;
`
export const SelectionSubTitleWrapper = styled.div`
    display: flex;
`
export const SelectionSubTitleText = styled(({ color, ...others }) => <span {...others} />)`
    margin: 0px 20px;
    font-size: 12px;
    color: white;
    background-color: ${props => props.color};
    text-align: center;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    font-weight: bold;
`
export const RadioGroup = styled.div`
    line-height: 25px;
    margin-top: 15px;
`